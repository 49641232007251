<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
        <!-- 这里是会被缓存的视图组件 -->
      </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive">
      <!-- 这里是不被缓存的视图组件 -->
    </router-view>
  </div>
</template>

<script>

export default {

}
</script>

<style lang="scss" scoped>
/deep/ .el-image-viewer__wrapper {
  z-index: 3000 !important;
}
</style>